$(document).ready(function() {
    $('.report-download .download').click(function(e){
        e.preventDefault();
        jQuery('#checkRegister').tableExport({type:'pdf',escape:'false'});
    });
    var date = new Date();
    $('#program-date-from').datepicker({
        autoclose: true
    }).datepicker('setDate', new Date(date.getFullYear(), date.getMonth(), 1));
    $('#program-date-to').datepicker({
        autoclose: true
    }).datepicker('setDate', new Date(date.getFullYear(), date.getMonth() + 1, 0));

    var table = $('#prospectReport').DataTable(
        {
            dom: 'Bfrtip',
            buttons: [
                {
                    text: 'Back',
                    action: function ( e, dt, node, config ) {
                        window.location.replace("/reports");
                    }
                },
                {
                    extend: 'pdfHtml5',
                    orientation: 'landscape',
                    pageSize: 'LEGAL',
                    title: function(){
                        if($(".report-title").html())
                            return $(".report-title").html().replace("\\n", "<br />");
                        else
                            return "";
                    },
                    filename: "Prospective Students",
                    customize: function(doc) {
                        doc.content[1].table.body[0][0].alignment = "left";
                        doc.content[1].table.body[0][1].alignment = "left";
                        doc.content[1].table.body[0][2].alignment = "left";
                        doc.content[1].table.body[0][3].alignment = "left";
                        doc.content[1].table.body[0][4].alignment = "left";
                        doc.content[1].table.body[0][5].alignment = "left";
                        doc.content[1].table.body[0][6].alignment = "left";
                        doc.content[1].table.body[0][7].alignment = "left";
                        doc.content[1].table.body[0][8].alignment = "left";

                        doc.content[1].table.widths = [ '15%','10%', '5%', '10%', '15%', '25%', '5%', '10%', '5%'];

                        var arr= doc.content[1].table.body;
                        var length = arr.length;
                        var records = length -1;
                        var sum_item = [
                            {
                                text: "Total: "+records.toString()+" Record(s)",
                                colSpan: 9
                            }
                        ];
                        doc.content[1].table.body.splice(length, 0, sum_item);

                        var today = new Date();
                        var dd = today.getDate();
                        var mm = today.getMonth()+1; //January is 0!

                        var yyyy = today.getFullYear();
                        if(dd<10){
                            dd='0'+dd
                        }
                        if(mm<10){
                            mm='0'+mm
                        }
                        var today = mm+'/'+dd+'/'+yyyy;

                        var cols = [];
                        cols[0] = {text: 'PRINT NAME AND TITLE', alignment: 'left', margin:[10] };
                        cols[1] = {text: 'DATE:', alignment: 'right', margin:[0,0,20] };
                        var objFooter = {};
                        objFooter['alignment'] = 'center';

                        doc["footer"] = objFooter;
                        doc["footer"] = function(currentPage, pageCount) {
                            var test = [
                                {
                                    alignment: 'left',
                                    stack: [
                                        {
                                            text: today,
                                            color: 'grey',
                                            fontSize: 8,
                                            alignment: 'left',
                                            margin:[20, 2, 0, 0]
                                        }

                                    ]
                                },
                                {
                                    stack: [
                                        {
                                            text: 'Page '+currentPage+" of "+pageCount,
                                            alignment: 'right',
                                            color: 'grey',
                                            fontSize: 8,
                                            margin:[0, 2, 20, 0]
                                        }
                                    ]
                                }
                            ];

                            objFooter['columns'] = test;

                            return objFooter;

                        };
                    }
                },
                {
                    extend: 'csvHtml5',
                    fieldSeparator: ',',
                    extension: '.csv'
                }
            ],
            "paging": true,
            "lengthChange": false,
            "autoWidth": false

        }
    );

    var table = $('#satisfactoryTable').DataTable(
        {
            dom: 'Bfrtip',
            buttons: [
                {
                    text: 'Back',
                    action: function ( e, dt, node, config ) {
                        window.location.replace("/reports");
                    }
                },
                {
                    extend: 'pdfHtml5',
                    orientation: 'portrait',
                    pageSize: 'LEGAL',
                    footer: true,
                    title: function(){
                        if($(".report-title").html())
                            return $(".report-title").html().replace("\\n", "<br />");
                        else
                            return "";
                    },
                    filename: "Satisfactory Report",
                    customize: function(doc) {

                        doc.content[1].table.body[0][1].alignment = "left";
                        doc.content[1].table.body[0][2].alignment = "left";
                        doc.content[1].table.body[0][3].alignment = "left";
                        doc.content[1].table.body[0][4].alignment = "left";
                        doc.content[1].table.body[0][5].alignment = "left";
                        doc.content[1].table.body[0][6].alignment = "left";
                        doc.content[1].table.body[0][7].alignment = "left";

                        doc.content[1].table.widths = [ '5%','15%', '15%', '15%', '13%', '13%', '7%', '17%'];

                        var arr= doc.content[1].table.body;
                        var length = arr.length;
                        var records = length -2;
                        var sum_item = [
                            {
                                text: "Total: "+records.toString()+" Record(s)",
                                colSpan: 8
                            }
                        ];
                        doc.content[1].table.body.splice(length, 0, sum_item);

                        var today = new Date();
                        var dd = today.getDate();
                        var mm = today.getMonth()+1; //January is 0!

                        var yyyy = today.getFullYear();
                        if(dd<10){
                            dd='0'+dd
                        }
                        if(mm<10){
                            mm='0'+mm
                        }
                        var today = mm+'/'+dd+'/'+yyyy;

                        var cols = [];
                        cols[0] = {text: 'PRINT NAME AND TITLE', alignment: 'left', margin:[10] };
                        cols[1] = {text: 'DATE:', alignment: 'right', margin:[0,0,20] };
                        var objFooter = {};
                        objFooter['alignment'] = 'center';

                        var test = [
                            {
                                alignment: 'left',
                                stack: [
                                    {
                                        canvas: [
                                            {
                                                type: 'line',
                                                x1: 20,
                                                y1: 5,
                                                x2: 133,
                                                y2: 5,
                                                lineWidth: 0.5
                                            }
                                        ]
                                    },
                                    {
                                        text: 'PRINT NAME AND TITLE',
                                        alignment: 'left',
                                        margin:[20, 5, 0, 0]
                                    },
                                    {
                                        text: today,
                                        color: 'grey',
                                        fontSize: 8,
                                        alignment: 'left',
                                        margin:[20, 2, 0, 0]
                                    }

                                ]
                            },
                            {
                                stack: [
                                    {
                                        canvas: [
                                            {
                                                type: 'line',
                                                x1: 240,
                                                y1: 5,
                                                x2: 286,
                                                y2: 5,
                                                lineWidth: 0.5
                                            }
                                        ]
                                    },
                                    {
                                        text: 'DATE',
                                        alignment: 'right',
                                        margin:[0, 5, 20, 0]
                                    }
                                ]
                            }
                        ];
                        objFooter['columns'] = test;
                        doc["footer"] = objFooter;
                        doc["footer"] = function(currentPage, pageCount) {
                            var test = [
                                {
                                    alignment: 'left',
                                    stack: [
                                        {
                                            canvas: [
                                                {
                                                    type: 'line',
                                                    x1: 20,
                                                    y1: 5,
                                                    x2: 133,
                                                    y2: 5,
                                                    lineWidth: 0.5
                                                }
                                            ]
                                        },
                                        {
                                            text: 'PRINT NAME AND TITLE',
                                            alignment: 'left',
                                            margin:[20, 5, 0, 0]
                                        },
                                        {
                                            text: today,
                                            color: 'grey',
                                            fontSize: 8,
                                            alignment: 'left',
                                            margin:[20, 2, 0, 0]
                                        }

                                    ]
                                },
                                {
                                    stack: [
                                        {
                                            canvas: [
                                                {
                                                    type: 'line',
                                                    x1: 240,
                                                    y1: 5,
                                                    x2: 286,
                                                    y2: 5,
                                                    lineWidth: 0.5
                                                }
                                            ]
                                        },
                                        {
                                            text: 'DATE',
                                            alignment: 'right',
                                            margin:[0, 5, 20, 0]
                                        },
                                        {
                                            text: 'Page '+currentPage+" of "+pageCount,
                                            alignment: 'right',
                                            color: 'grey',
                                            fontSize: 8,
                                            margin:[0, 2, 20, 0]
                                        }
                                    ]
                                }
                            ];

                            objFooter['columns'] = test;

                            return objFooter;

                        };
                    }
                },
                {
                    extend: 'csvHtml5',
                    fieldSeparator: ',',
                    extension: '.csv'
                }
            ],
            "paging": true,
            "lengthChange": false,
            "autoWidth": false,
            "footerCallback": function ( row, data, start, end, display ) {
                var api = this.api(), data;

                // Remove the formatting to get integer data for summation
                var intVal = function ( i ) {
                    return typeof i === 'string' ?
                    i.replace(/[\$,]/g, '')*1 :
                        typeof i === 'number' ?
                            i : 0;
                };

                // Total over all pages
                total = api
                    .column( 7 )
                    .data()
                    .reduce( function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0 );

                // Total over this page
                pageTotal = api
                    .column( 7, { page: 'current'} )
                    .data()
                    .reduce( function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0 );

                pageSearchTotal = api
                    .column( 7, { search: 'applied'} )
                    .data()
                    .reduce( function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0 );

                // Update footer

                $( api.column(7).footer() ).html(
                    "$" + pageSearchTotal.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
                );
            }
        }
    );

    var table = $('#checkRegister').DataTable(
        {
            dom: 'Bfrtip',
            buttons: [
                {
                    text: 'Back',
                    action: function ( e, dt, node, config ) {
                        window.location.replace("/reports");
                    }
                },
                {
                    extend: 'pdfHtml5',
                    orientation: 'landscape',
                    pageSize: 'LEGAL',
                    title: function(){
                        if($(".report-title").html())
                            return $(".report-title").html().replace("\\n", "<br />");
                        else
                            return "";
                    },
                    footer: true,
                    filename: "Check Register",
                    customize: function(doc) {
                        doc.content[1].table.body[0][0].alignment = "left";
                        doc.content[1].table.body[0][1].alignment = "left";
                        doc.content[1].table.body[0][2].alignment = "left";
                        doc.content[1].table.body[0][3].alignment = "left";
                        doc.content[1].table.body[0][4].alignment = "left";
                        doc.content[1].table.body[0][5].alignment = "left";
                        doc.content[1].table.body[0][6].alignment = "left";
                        doc.content[1].table.body[0][7].alignment = "left";
                        doc.content[1].table.body[0][8].alignment = "left";

                        doc.content[1].table.widths = [ '15%','15%', '15%', '5%', '15%', '10%', '10%', '5%', '10%'];


                        var arr= doc.content[1].table.body;
                        var length = arr.length;
                        var records = length -2;
                        var sum_item = [
                            {
                                text: "Total: "+records.toString()+" Record(s)",
                                colSpan: 9
                            }
                        ];
                        doc.content[1].table.body.splice(length, 0, sum_item);

                        var today = new Date();
                        var dd = today.getDate();
                        var mm = today.getMonth()+1; //January is 0!

                        var yyyy = today.getFullYear();
                        if(dd<10){
                            dd='0'+dd
                        }
                        if(mm<10){
                            mm='0'+mm
                        }
                        var today = mm+'/'+dd+'/'+yyyy;

                        var objFooter = {};
                        objFooter['alignment'] = 'center';

                        doc["footer"] = objFooter;
                        doc["footer"] = function(currentPage, pageCount) {
                            var test = [
                                {
                                    alignment: 'left',
                                    stack: [
                                        {
                                            text: today,
                                            color: 'grey',
                                            fontSize: 8,
                                            alignment: 'left',
                                            margin:[20, 2, 0, 0]
                                        }

                                    ]
                                },
                                {
                                    stack: [
                                        {
                                            text: 'Page '+currentPage+" of "+pageCount,
                                            alignment: 'right',
                                            color: 'grey',
                                            fontSize: 8,
                                            margin:[0, 2, 20, 0]
                                        }
                                    ]
                                }
                            ];

                            objFooter['columns'] = test;

                            return objFooter;

                        };

                    }
                },
                {
                    extend: 'csvHtml5',
                    fieldSeparator: ',',
                    extension: '.csv'
                }
            ],
            "paging": true,
            "lengthChange": false,
            "autoWidth": false,
            "footerCallback": function ( row, data, start, end, display ) {
                var api = this.api(), data;

                // Remove the formatting to get integer data for summation
                var intVal = function ( i ) {
                    return typeof i === 'string' ?
                    i.replace(/[\$,]/g, '')*1 :
                        typeof i === 'number' ?
                            i : 0;
                };
                var count = 0;
                // Total over all pages
                total = api
                    .column( 8 )
                    .data()
                    .reduce( function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0 );

                // Total over this page
                pageTotal = api
                    .column( 8, { page: 'current'} )
                    .data()
                    .reduce( function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0 );

                pageSearchTotal = api
                    .column( 8, { search: 'applied'} )
                    .data()
                    .reduce( function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0 );

                // Update footer

                $( api.column(8).footer() ).html(
                    "$" + pageSearchTotal.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
                );

            }
        }
    );

    var table = $('#checkRegisterByProgram').DataTable(
        {
            dom: 'Bfrtip',
            buttons: [
                {
                    text: 'Back',
                    action: function ( e, dt, node, config ) {
                        window.location.replace("/reports");
                    }
                },
                {
                    extend: 'pdfHtml5',
                    orientation: 'landscape',
                    pageSize: 'LEGAL',
                    title: function(){
                        if($(".report-title").html())
                            return $(".report-title").html().replace("\\n", "<br />");
                        else
                            return "";
                    },
                    footer: true,
                    filename: "Check Register",
                    customize: function(doc) {
                        var program = [];
                        var sum = 0;
                        var count = 0;

                        var arr_count = doc.content[1].table.body;
                        var length_count = arr_count.length;
                        var num_records = length_count -2;

                        doc.content[1].table.body[0][0].alignment = "left";
                        doc.content[1].table.body[0][1].alignment = "left";
                        doc.content[1].table.body[0][2].alignment = "left";
                        doc.content[1].table.body[0][3].alignment = "left";
                        doc.content[1].table.body[0][4].alignment = "left";
                        doc.content[1].table.body[0][5].alignment = "left";
                        doc.content[1].table.body[0][6].alignment = "left";

                        doc.content[1].table.widths = [ '0%','10%', '10%', '20%', '20%', '20%', "20%"];

                        var arr = doc.content[1].table.body;
                        var len = arr.length;

                        for (var i = 0; i < len; i++) {
                            var entry = arr[i];
                            entry[0].maxWidth = 0;
                            if(i > 0)
                            {
                                if(doc.content[1].table.body[i-1][0].text != entry[0].text && entry[0].text != "" && entry[0].text != "Program" || i == len-1)
                                {
                                    if(arr[i-1][0].text.indexOf("Program Record") == -1 && arr[i-1][0].text.indexOf("Program Sum") == -1)
                                    {
                                        var program_item = [
                                            {
                                                text: "Program Record"
                                            },
                                            {
                                                text: entry[0].text,
                                                colSpan: 6,
                                                fillColor: "#dcdcdc"
                                            },
                                            {
                                                text: ""
                                            },
                                            {
                                                text: ""
                                            },
                                            {
                                                text: ""
                                            },
                                            {
                                                text: ""
                                            },
                                            {
                                                text: ""
                                            }
                                        ];
                                        doc.content[1].table.body.splice(i, 0, program_item);
                                        len++;
                                    }
                                    if(sum > 0)
                                    {
                                        var sum_item = [
                                            {
                                                text: "Program Sum"
                                            },
                                            {
                                                text: "Record(s): "+count,
                                                colSpan: 4,
                                                "style": "strong"
                                            },
                                            {
                                                text: ""
                                            },
                                            {
                                                text: ""
                                            },
                                            {
                                                text: ""
                                            },
                                            {
                                                text: ""
                                            },
                                            {
                                                text: "Program Total: $"+sum.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                            }
                                        ];
                                        doc.content[1].table.body.splice(i, 0, sum_item);
                                        len++;
                                    }
                                    sum = 0;
                                    count = 0;
                                }
                                else if(doc.content[1].table.body[i-1][0].text == entry[0].text && entry[0].text)
                                {
                                    if(typeof entry[6] === 'undefined')
                                    {

                                    }
                                    else
                                    {
                                        count++;
                                        sum += Number(entry[6].text.replace(/[^0-9\.]+/g,""));
                                    }
                                }

                            }
                        }

                        doc.content[1].table.body.forEach(function(entry, i) {
                            if(entry.length > 1)
                            {
                                entry[0].fontSize = 0;
                            }
                        });

                        var today = new Date();
                        var dd = today.getDate();
                        var mm = today.getMonth()+1; //January is 0!

                        var yyyy = today.getFullYear();
                        if(dd<10){
                            dd='0'+dd
                        }
                        if(mm<10){
                            mm='0'+mm
                        }
                        var today = mm+'/'+dd+'/'+yyyy;

                        var cols = [];
                        cols[0] = {text: 'PRINT NAME AND TITLE', alignment: 'left', margin:[10] };
                        cols[1] = {text: 'DATE:', alignment: 'right', margin:[0,0,20] };
                        var objFooter = {};
                        objFooter['alignment'] = 'center';

                        doc["footer"] = objFooter;
                        doc["footer"] = function(currentPage, pageCount) {
                            var test = [
                                {
                                    alignment: 'left',
                                    stack: [
                                        {
                                            text: today,
                                            color: 'grey',
                                            fontSize: 8,
                                            alignment: 'left',
                                            margin:[20, 2, 0, 0]
                                        }

                                    ]
                                },
                                {
                                    stack: [
                                        {
                                            text: 'Page '+currentPage+" of "+pageCount,
                                            alignment: 'right',
                                            color: 'grey',
                                            fontSize: 8,
                                            margin:[0, 2, 20, 0]
                                        }
                                    ]
                                }
                            ];
                            objFooter['columns'] = test;
                            return objFooter;
                        };

                        var sum_item = [
                            {
                                text: "Total: "+num_records.toString()+" Record(s)",
                                colSpan: 7
                            }
                        ];
                        doc.content[1].table.body.splice(len, 0, sum_item);
                    }
                },
                {
                    extend: 'csvHtml5',
                    fieldSeparator: ',',
                    extension: '.csv'
                }
            ],
            "paging": true,
            "lengthChange": false,
            "autoWidth": false,
            "order": [[ 0, 'asc' ]],
            "displayLength": 25,
            "columnDefs": [
                { "visible": false, "targets": 0 }
            ],
            "drawCallback": function ( settings ) {
                var api = this.api();
                var rows = api.rows( {page:'current'} ).nodes();
                var last=null;

                api.column(0, {page:'current'} ).data().each( function ( group, i ) {
                    if ( last !== group ) {
                        $(rows).eq( i ).before(
                            '<tr class="group"><td colspan="6">'+group+'</td></tr>'
                        );

                        last = group;
                    }
                } );
            },
            "footerCallback": function ( row, data, start, end, display ) {
                var api = this.api(), data;

                // Remove the formatting to get integer data for summation
                var intVal = function ( i ) {
                    return typeof i === 'string' ?
                    i.replace(/[\$,]/g, '')*1 :
                        typeof i === 'number' ?
                            i : 0;
                };

                // Total over all pages
                total = api
                    .column( 6 )
                    .data()
                    .reduce( function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0 );

                // Total over this page
                pageTotal = api
                    .column( 6, { page: 'current'} )
                    .data()
                    .reduce( function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0 );

                pageSearchTotal = api
                    .column( 6, { search: 'applied'} )
                    .data()
                    .reduce( function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0 );

                // Update footer
                // Update footer
                $( api.column(6).footer() ).html(
                    "$" + pageSearchTotal.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
                );
            }
        }
    );

    var table = $('#financialAid').DataTable(
        {
            dom: 'Bfrtip',
            buttons: [
                {
                    text: 'Back',
                    action: function ( e, dt, node, config ) {
                        window.location.replace("/reports");
                    }
                },
                {
                    extend: 'pdfHtml5',
                    orientation: 'landscape',
                    pageSize: 'A3',
                    title: function(){
                        if($(".report-title").html())
                            return $(".report-title").html().replace("\\n", "<br />");
                        else
                            return "";
                    },
                    footer: true,
                    filename: "Financial Aid",
                    customize: function(doc) {

                        var length = $('#financialAid thead th').length - 6;
                        var remaining_width = 50/length+"%";
                        var widths = [ '10%','10%', '10%', '5%', '5%' , "10%"];

                        for (i = 0; i < length; i++) {
                            widths.push(remaining_width)
                        }

                        doc.content[1].table.widths = widths;

                        var arr= doc.content[1].table.body;
                        var length = arr.length;
                        var records = length -2;
                        var sum_item = [
                            {
                                text: "Total: "+records.toString()+" Record(s)",
                                colSpan: $('#financialAid thead th').length
                            }
                        ];
                        doc.content[1].table.body.splice(length, 0, sum_item);



                        var today = new Date();
                        var dd = today.getDate();
                        var mm = today.getMonth()+1; //January is 0!

                        var yyyy = today.getFullYear();
                        if(dd<10){
                            dd='0'+dd
                        }
                        if(mm<10){
                            mm='0'+mm
                        }
                        var today = mm+'/'+dd+'/'+yyyy;

                        var cols = [];
                        cols[0] = {text: 'PRINT NAME AND TITLE', alignment: 'left', margin:[10] };
                        cols[1] = {text: 'DATE:', alignment: 'right', margin:[0,0,20] };
                        var objFooter = {};
                        objFooter['alignment'] = 'center';

                        doc["footer"] = objFooter;
                        doc["footer"] = function(currentPage, pageCount) {
                            var test = [
                                {
                                    alignment: 'left',
                                    stack: [
                                        {
                                            text: today,
                                            color: 'grey',
                                            fontSize: 8,
                                            alignment: 'left',
                                            margin:[20, 2, 0, 0]
                                        }

                                    ]
                                },
                                {
                                    stack: [
                                        {
                                            text: 'Page '+currentPage+" of "+pageCount,
                                            alignment: 'right',
                                            color: 'grey',
                                            fontSize: 8,
                                            margin:[0, 2, 20, 0]
                                        }
                                    ]
                                }
                            ];

                            objFooter['columns'] = test;

                            return objFooter;

                        };
                    }
                },
                {
                    extend: 'csvHtml5',
                    fieldSeparator: ',',
                    extension: '.csv'
                }
            ],
            "paging": true,
            "lengthChange": false,
            "autoWidth": false,
            "order": [[ 0, 'asc' ]],
            "displayLength": 25,
            "footerCallback": function ( row, data, start, end, display ) {
                var api = this.api(), data;

                // Remove the formatting to get integer data for summation
                var intVal = function ( i ) {
                    return typeof i === 'string' ?
                    i.replace(/[\$,]/g, '')*1 :
                        typeof i === 'number' ?
                            i : 0;
                };

                var length = $('#financialAid thead th').length;

                for (i = 6; i < length; i++) {
                    total = api
                        .column( i )
                        .data()
                        .reduce( function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0 );

                    pageTotal = api
                        .column( i, { page: 'current'} )
                        .data()
                        .reduce( function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0 );

                    pageSearchTotal = api
                        .column( i, { search: 'applied'} )
                        .data()
                        .reduce( function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0 );

                    $( api.column(i).footer() ).html(
                        "$" + pageSearchTotal.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
                    );

                }
            }
        }
    );

    var table = $('#financialAidProgram').DataTable(
        {
            dom: 'Bfrtip',
            buttons: [
                {
                    text: 'Back',
                    action: function ( e, dt, node, config ) {
                        window.location.replace("/reports");
                    }
                },
                {
                    extend: 'pdfHtml5',
                    orientation: 'landscape',
                    pageSize: 'A3',
                    title: function(){
                        if($(".report-title").html())
                            return $(".report-title").html().replace("\\n", "<br />");
                        else
                            return "";
                    },
                    footer: true,
                    filename: "Financial Aid",
                    customize: function(doc) {

                        var length = $('#financialAidProgram thead th').length - 6;
                        var remaining_width = 50/length+"%";
                        var widths = ["0%", '10%','10%', '10%', '5%', '5%' , "10%"];
                        doc.content[1].table.widths = widths;



                        for(f = 0; f <= $('#financialAidProgram thead th').length; f++)
                        {
                            doc.content[1].table.body[0][f].alignment = "left";
                        }

                        for (i = 0; i < length; i++) {
                            widths.push(remaining_width)

                        }
                        var sum = [];
                        for (var z = 0; z < length; z++) {
                            sum[z+7] = 0;
                        }
                        var checksum = false;
                        var sum_pushed = false; var count = 0;
                        var arr = doc.content[1].table.body;
                        var len = arr.length;
                        for (var i = 0; i < len; i++) {

                            var entry = arr[i];
                            entry[0].maxWidth = 0;
                            if(i > 0)
                            {
                                if(doc.content[1].table.body[i-1][0].text == entry[0].text || i == 2)
                                {
                                    for (var z = 0; z < length; z++) {
                                        if(typeof entry[z+7] === 'undefined')
                                        {

                                        }
                                        else
                                        {
                                            sum[z+7] += Number(entry[z+7].text.replace(/[^0-9\.]+/g,""));
                                        }
                                    }
                                    count++;
                                }
                                else if(arr[i-1][0].text != entry[0].text && entry[0].text != "" && entry[0].text != "Program" || i == len-1)
                                {
                                    for (var z = 0; z < length; z++) {
                                        if(sum[z+7] > 0)
                                        {
                                            checksum = true; break;
                                        }

                                    }

                                    if(arr[i-1][0].text.indexOf("Program Record") == -1 && arr[i-1][0].text.indexOf("Program Sum") == -1)
                                    {
                                        var financial_program_push = [
                                            {
                                                text:"Program Record"
                                            },
                                            {
                                                text: entry[0].text,
                                                colSpan: $('#financialAidProgram thead th').length,
                                                fillColor: "#dcdcdc"
                                            },
                                            {},
                                            {},
                                            {},
                                            {},
                                            {}

                                        ];
                                        for (s = 0; s < length; s++)
                                        {
                                            financial_program_push.push({})
                                        }

                                        doc.content[1].table.body.splice(i, 0, financial_program_push);
                                        len++;
                                    }

                                    if((arr[i-1][0].text.indexOf("Program Record") == -1 && arr[i-1][0].text.indexOf("Program Sum") == -1 && checksum) && count > 1)
                                    {
                                        var financial_sum_push = [
                                            {
                                                text:"Program Sum"
                                            },
                                            {
                                                text: "Record(s): "+count
                                            },
                                            {
                                                text:""
                                            },
                                            {
                                                text:""
                                            },
                                            {
                                                text:""
                                            },
                                            {
                                                text:""
                                            },
                                            {
                                                text:""
                                            }

                                        ];
                                        for (d = 0; d < length; d++)
                                        {
                                            financial_sum_push.push(
                                                {
                                                    text: "$"+sum[d+7].toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
                                                }

                                            )
                                        }
                                        doc.content[1].table.body.splice(i, 0, financial_sum_push);
                                        len++;

                                        for (var z = 0; z < length; z++) {
                                            sum[z+7] = 0;
                                        }

                                        count = 1;

                                        checksum = false;
                                    }
                                }


                            }
                        }

                        arr.forEach(function(entry, i) {
                            if(entry.length > 1)
                            {
                                entry[0].fontSize = 0;
                            }
                        });

                        var today = new Date();
                        var dd = today.getDate();
                        var mm = today.getMonth()+1; //January is 0!

                        var yyyy = today.getFullYear();
                        if(dd<10){
                            dd='0'+dd
                        }
                        if(mm<10){
                            mm='0'+mm
                        }
                        var today = mm+'/'+dd+'/'+yyyy;

                        var cols = [];
                        cols[0] = {text: 'PRINT NAME AND TITLE', alignment: 'left', margin:[10] };
                        cols[1] = {text: 'DATE:', alignment: 'right', margin:[0,0,20] };
                        var objFooter = {};
                        objFooter['alignment'] = 'center';

                        doc["footer"] = objFooter;
                        doc["footer"] = function(currentPage, pageCount) {
                            var test = [
                                {
                                    alignment: 'left',
                                    stack: [
                                        {
                                            text: today,
                                            color: 'grey',
                                            fontSize: 8,
                                            alignment: 'left',
                                            margin:[20, 2, 0, 0]
                                        }

                                    ]
                                },
                                {
                                    stack: [
                                        {
                                            text: 'Page '+currentPage+" of "+pageCount,
                                            alignment: 'right',
                                            color: 'grey',
                                            fontSize: 8,
                                            margin:[0, 2, 20, 0]
                                        }
                                    ]
                                }
                            ];

                            objFooter['columns'] = test;

                            return objFooter;

                        };
                    }
                },
                {
                    extend: 'csvHtml5',
                    fieldSeparator: ',',
                    extension: '.csv'
                }
            ],
            "paging": true,
            "lengthChange": false,
            "autoWidth": false,

            "columnDefs": [
                { "visible": false, "targets": 0 }
            ],
            "order": [[ 0, 'asc' ]],
            "displayLength": 25,
            "drawCallback": function ( settings ) {
                var api = this.api();
                var columnscount = $("#financialAidProgram thead th").length;
                var rows = api.rows( {page:'current'} ).nodes();
                var last=null;

                api.column(0, {page:'current'} ).data().each( function ( group, i ) {
                    if ( last !== group ) {
                        $(rows).eq( i ).before(
                            '<tr class="group"><td colspan="'+columnscount+'">'+group+'</td></tr>'
                        );

                        last = group;
                    }
                } );
            },
            "footerCallback": function ( row, data, start, end, display ) {
                var api = this.api(), data;

                // Remove the formatting to get integer data for summation
                var intVal = function ( i ) {
                    return typeof i === 'string' ?
                    i.replace(/[\$,]/g, '')*1 :
                        typeof i === 'number' ?
                            i : 0;
                };

                var length = $('#financialAidProgram thead th').length;

                for (i = 7; i < length + 1; i++) {
                    total = api
                        .column( i )
                        .data()
                        .reduce( function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0 );

                    pageTotal = api
                        .column( i, { page: 'current'} )
                        .data()
                        .reduce( function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0 );

                    pageSearchTotal = api
                        .column( i, { search: 'applied'} )
                        .data()
                        .reduce( function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0 );

                    $( api.column(i).footer() ).html(
                        "$" + pageSearchTotal.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
                    );

                }
            }
        }
    );

    var table = $('#fundingByProgram').DataTable(
        {
            dom: 'Bfrtip',
            buttons: [
                {
                    text: 'Back',
                    action: function ( e, dt, node, config ) {
                        window.location.replace("/reports");
                    }
                },
                {
                    extend: 'pdfHtml5',
                    orientation: 'landscape',
                    pageSize: 'A3',
                    title: function(){
                        if($(".report-title").html())
                            return $(".report-title").html().replace("\\n", "<br />");
                        else
                            return "";
                    },
                    footer: true,
                    filename: "Financial Aid",
                    customize: function(doc) {

                        var length = $('#fundingByProgram thead th').length - 6;
                        var remaining_width = 50/length+"%";
                        var widths = ["0%", '10%','10%', '10%', '5%', '5%' , "10%"];
                        doc.content[1].table.widths = widths;



                        for(f = 0; f <= $('#fundingByProgram thead th').length; f++)
                        {
                            doc.content[1].table.body[0][f].alignment = "left";
                        }

                        for (i = 0; i < length; i++) {
                            widths.push(remaining_width)

                        }
                        var sum = [];
                        for (var z = 0; z < length; z++) {
                            sum[z+7] = 0;
                        }
                        var checksum = false;
                        var sum_pushed = false; var count = 0;
                        var arr = doc.content[1].table.body;
                        var len = arr.length;
                        for (var i = 0; i < len; i++) {

                            var entry = arr[i];
                            entry[0].maxWidth = 0;
                            if(i > 0)
                            {
                                if(doc.content[1].table.body[i-1][0].text == entry[0].text || i == 2)
                                {
                                    for (var z = 0; z < length; z++) {
                                        if(typeof entry[z+7] === 'undefined')
                                        {

                                        }
                                        else
                                        {

                                        }
                                    }
                                    count++;
                                }
                                else if(arr[i-1][0].text != entry[0].text && entry[0].text != "" && entry[0].text != "Program" || i == len-1)
                                {
                                    for (var z = 0; z < length; z++) {
                                        if(sum[z+7] > 0)
                                        {
                                            checksum = true; break;
                                        }

                                    }

                                    if(arr[i-1][0].text.indexOf("Program Record") == -1 && arr[i-1][0].text.indexOf("Program Sum") == -1)
                                    {
                                        var financial_program_push = [
                                            {
                                                text:"Program Record"
                                            },
                                            {
                                                text: entry[0].text,
                                                colSpan: $('#fundingByProgram thead th').length,
                                                fillColor: "#dcdcdc"
                                            },
                                            {},
                                            {},
                                            {},
                                            {},
                                            {}

                                        ];
                                        for (s = 0; s < length; s++)
                                        {
                                            financial_program_push.push({})
                                        }

                                        doc.content[1].table.body.splice(i, 0, financial_program_push);
                                        len++;
                                    }

                                    if((arr[i-1][0].text.indexOf("Program Record") == -1 && arr[i-1][0].text.indexOf("Program Sum") == -1 && checksum) && count > 1)
                                    {
                                        var financial_sum_push = [
                                            {
                                                text:""
                                            },
                                            {
                                                text: ""
                                            },
                                            {
                                                text:""
                                            },
                                            {
                                                text:""
                                            },
                                            {
                                                text:""
                                            },
                                            {
                                                text:""
                                            },
                                            {
                                                text:""
                                            }

                                        ];
                                        for (d = 0; d < length; d++)
                                        {
                                            financial_sum_push.push(
                                                {
                                                    text: ""
                                                }

                                            )
                                        }
                                        doc.content[1].table.body.splice(i, 0, financial_sum_push);
                                        len++;

                                        for (var z = 0; z < length; z++) {
                                            sum[z+7] = 0;
                                        }

                                        count = 1;

                                        checksum = false;
                                    }
                                }


                            }
                        }

                        arr.forEach(function(entry, i) {
                            if(entry.length > 1)
                            {
                                entry[0].fontSize = 0;
                            }
                        });

                        var today = new Date();
                        var dd = today.getDate();
                        var mm = today.getMonth()+1; //January is 0!

                        var yyyy = today.getFullYear();
                        if(dd<10){
                            dd='0'+dd
                        }
                        if(mm<10){
                            mm='0'+mm
                        }
                        var today = mm+'/'+dd+'/'+yyyy;

                        var cols = [];
                        cols[0] = {text: 'PRINT NAME AND TITLE', alignment: 'left', margin:[10] };
                        cols[1] = {text: 'DATE:', alignment: 'right', margin:[0,0,20] };
                        var objFooter = {};
                        objFooter['alignment'] = 'center';

                        doc["footer"] = objFooter;
                        doc["footer"] = function(currentPage, pageCount) {
                            var test = [
                                {
                                    alignment: 'left',
                                    stack: [
                                        {
                                            text: today,
                                            color: 'grey',
                                            fontSize: 8,
                                            alignment: 'left',
                                            margin:[20, 2, 0, 0]
                                        }

                                    ]
                                },
                                {
                                    stack: [
                                        {
                                            text: 'Page '+currentPage+" of "+pageCount,
                                            alignment: 'right',
                                            color: 'grey',
                                            fontSize: 8,
                                            margin:[0, 2, 20, 0]
                                        }
                                    ]
                                }
                            ];

                            objFooter['columns'] = test;

                            return objFooter;

                        };
                    }
                },
                {
                    extend: 'csvHtml5',
                    fieldSeparator: ',',
                    extension: '.csv'
                }
            ],
            "paging": true,
            "lengthChange": false,
            "autoWidth": false,

            "columnDefs": [
                { "visible": false, "targets": 0 }
            ],
            "order": [[ 0, 'asc' ]],
            "displayLength": 25,
            "drawCallback": function ( settings ) {
                var api = this.api();
                var columnscount = $("#fundingByProgram thead th").length;
                var rows = api.rows( {page:'current'} ).nodes();
                var last=null;

                api.column(0, {page:'current'} ).data().each( function ( group, i ) {
                    if ( last !== group ) {
                        $(rows).eq( i ).before(
                            '<tr class="group"><td colspan="'+columnscount+'"><strong>'+group+'</strong></td></tr>'
                        );

                        last = group;
                    }
                } );
            },
            "footerCallback": function ( row, data, start, end, display ) {
                var api = this.api(), data;

                // Remove the formatting to get integer data for summation
                var intVal = function ( i ) {
                    return typeof i === 'string' ?
                    i.replace(/[\$,]/g, '')*1 :
                        typeof i === 'number' ?
                            i : 0;
                };

                var length = $('#fundingByProgram thead th').length;

                for (i = 7; i < length + 1; i++) {
                    total = api
                        .column( i )
                        .data()
                        .reduce( function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0 );

                    pageTotal = api
                        .column( i, { page: 'current'} )
                        .data()
                        .reduce( function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0 );

                    pageSearchTotal = api
                        .column( i, { search: 'applied'} )
                        .data()
                        .reduce( function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0 );

                    $( api.column(i).footer() ).html(
                        "$" + pageSearchTotal.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
                    );

                }
            }
        }
    );

    var programTable = $('.programTable')
    // Apply the search

    // DataTable

    $('.programTable tbody').on( 'click', 'tr', function () {
        if ( !$(this).hasClass('selected') )  {
            $(this).addClass('selected').siblings().removeClass('selected');
        }
    } );


    $('.single-table').on( 'click', 'tr', function () {
        if ( !$(this).hasClass('selected') )  {
            $(this).addClass('selected').siblings().removeClass('selected');
        }
    } );

    $('#profile-tabs').tab();
});

$(document).on('click', '.btn-modal-delete', function(e){
    e.preventDefault();
    $('#confirmDeleteModal').modal('show');
    $('#confirmDeleteModal').find('.btn-confirm-delete').attr('data-href', $(this).attr('href'));
    return false;
});

$(document).on('click','.btn-confirm-delete', function(){
    S.deleteRequest($(this).attr('data-href'), function(resp){
        $('.user-'+resp.data.id).fadeOut().remove();
    }, function(resp) {
        // bs_alert('error', resp);
    });
    $('#confirmDeleteModal').modal('hide');

});

$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
});

var S = {
    deleteRequest: function (urlCall, callback, errorCallback) {
        $.ajax({
            url: urlCall,
            type: 'DELETE',
            success: callback || $.noop,
            error: errorCallback || $.noop
       });
    },
    postRequest: function (urlCall, data, callback, errorCallback) {
        $.ajax({
            url: urlCall,
            type: 'POST',
            data: data || {},
            success: callback || $.noop,
            error: errorCallback || $.noop
        });
    }
};


if ($('#vue-profile').length) {
    new Vue({
        el: '#vue-profile',
        data: {
            studentDetails: {student: {}, programs: {}},
            students: {},
            studentProgram: {},
            studentPayments: {},
            studentFunding: {},
            studentFundingProgram: [],
            totalFee: "",
            totalAllowances: "",
            programMonths: "",
            totalYearlyAllowance: "",
            totalFunding: "",
            totalFundingFee: ""
        },
        ready: function() {
            this.students = [];
            $.getJSON('/students/students/', function(data){
                this.students = data;

                setTimeout(function(){
                    var table = $('#students-filter').DataTable(
                        {
                            "ordering": false,
                            "info": false,
                            "paging": true,
                            "pageLength": 5,
                            "lengthChange": false,
                            "autoWidth": false,
                            "columns": [
                                {
                                    "width": "100px"
                                },
                                null,
                                null,
                                {
                                    "width": "15px"
                                }
                            ]
                        }
                    );

                    $('#students-filter thead th').each( function () {
                        var title = $(this).text();
                        $(this).html( '<input type="text" placeholder="'+title+'" />' );
                    } );

                    table.columns().every(function () {
                        var that = this;
                        $('input', this.header()).on( 'keyup change', function () {
                            if ( that.search() !== this.value ) {
                                that
                                    .search("^" + $(this).val(), true, false, true)
                                    .draw();
                            }
                        } );
                    });

                    $('#students-filter tbody').on( 'click', 'tr', function () {
                        if ( !$(this).hasClass('selected') )  {
                            table.$('tr.selected').removeClass('selected');
                            $(this).addClass('selected');
                        }
                    } );

                    // Setup - add a text input to each footer cell

                }, 0);
            }.bind(this));
        },
        computed: {
            totalFee: function() {
                return this.studentProgram.Tuition + this.studentProgram.Registration + this.studentProgram.STRFFee + this.studentProgram.OtherFee1 + this.studentProgram.OtherFee2 + this.studentProgram.BooksFee;
            },
            totalAllowances: function () {
                return this.studentProgram.FoodHousingExp + this.studentProgram.TransportationExp + this.studentProgram.PersonalExp + this.studentProgram.ChildCareExp + this.studentProgram.OtherExp
            },
            programMonths: function() {
                if(this.studentProgram.ProgramWeeks)
                    return Math.round(this.studentProgram.ProgramWeeks/4.33);
                else
                    return "";
            },
            totalYearlyAllowance: function() {
                return this.programMonths * this.totalAllowances;
            },
            totalFunding: function() {
                return this.studentFundingProgram.reduce(function(total, item) {
                    return total + (1 * item.FundingAmount)
                }, 0)
            },
            totalFundingFAwStafford: function(){
                return this.studentFundingProgram.reduce(function(total, item) {
                    return total + (item.FAwStafford == 1 ? (1 * item.FundingAmount) : 0)
                }, 0)
            },
            totalFundingFAwoStafford: function(){
                return this.studentFundingProgram.reduce(function(total, item) {
                    return total + (item.FAwoStafford == 1 ? (1 * item.FundingAmount) : 0)
                }, 0)
            },
            totalFundingFee: function() {
                return this.studentFundingProgram.reduce(function(total, item) {
                    return total + item.LoanFee * item.FundingAmount
                }, 0)
            },
            studentProfileReportURL: function() {
                if(this.studentProgram.profile_id && this.studentDetails.student.SSN)
                    return "/students/report/profile/student/"+this.studentDetails.student.SSN+"/program/"+this.studentProgram.profile_id;
                else
                    return null;
            },
            studentPaymentReportURL: function() {
                if(this.studentProgram.profile_id && this.studentDetails.student.SSN)
                    return "/students/report/payment/student/"+this.studentDetails.student.SSN+"/program/"+this.studentProgram.profile_id;
                else
                    return null;
            }
        },
        methods: {
            loadPrograms: function(ssn) {
                this.studentProgram = [];
                $.getJSON('/students/info/'+ssn, function(data){
                    this.studentDetails = data;
                    this.studentPayments = data.payments;
                    this.studentFunding = data.funding;
                }.bind(this));

            },
            loadAdditional: function(studentProgram) {

                this.studentProgram = studentProgram;

                var tmp = {};
                var newArray = [];
                var payments = [];
                this.studentFundingProgram = [];

                this.studentPayments.forEach(function(element){
                   if(studentProgram.profile_id == element.StudentProfileID)
                   {
                       payments.push(element);
                   }
                });
                this.studentFunding.forEach(function(element) {

                    if(studentProgram.profile_id == element.StudentProfileID)
                    {

                        if (tmp[element.FedPgmID] != undefined) {
                            tmp[element.FedPgmID].total_count += 1 ;
                            tmp[element.FedPgmID].FundingAmount = parseFloat(tmp[element.FedPgmID].FundingAmount) + parseFloat(element.FundingAmount) ;
                            tmp[element.FedPgmID].LoanFee = parseFloat(tmp[element.FedPgmID].LoanFee) + parseFloat(element.LoanFee) ;
                            console.log(tmp[element.FedPgmID].LoanFee);
                        } else {
                            tmp[element.FedPgmID] = element;
                            tmp[element.FedPgmID].total_count = 1;
                        }

                    }

                });


                $.each( tmp, function( key, value ) {
                    newArray.push(value);
                });
                this.studentPayments = payments;
                this.studentFundingProgram = newArray;

            }
        }

    });
}


if ($('#vue-report').length) {
    new Vue({
        el: '#vue-report',
        data: {
            reports: {
                configure: {
                    dates: [
                        {
                            id: 1,
                            name: "Check"
                        },
                        {
                            id: 2,
                            name: "Scheduled"
                        }
                    ],
                    schools: [
                        {
                            id: "",
                            name: "All Schools"
                        },
                        {
                            id: "CBD",
                            name: "CBD"
                        },
                        {
                            id: "VCMC",
                            name: "VCMC"
                        },
                        {
                            id: "Mayfield",
                            name: "Mayfield"
                        },
                        {
                            id: "UAV",
                            name: "UAV"
                        }
                    ],
                    names: [
                        {
                            id: 1,
                            name: "Check Register",
                            active: false
                        },
                        {
                            id: 2,
                            name: "Check Register by Study Program",
                            active: false
                        },
                        {
                            id: 3,
                            name: "Financial Aid Awarded",
                            active: false
                        },
                        {
                            id: 4,
                            name: "Financial Aid Awarded by Study Program",
                            active: false
                        },
                        {
                            id: 5,
                            name: "Satisfactory Academic Progress (Funding Projections and Refunds)",
                            active: false
                        },
                        {
                            id: 6,
                            name: "Prospective Students",
                            active: false
                        },
                        {
                            id: 7,
                            name: "Student Funding",
                            active: false
                        }
                    ],
                    enrollmentStatus: "",
                    studyPrograms: "",
                    federalPrograms: "",
                    1: {
                        enrollmentStatus: [

                        ],
                        studyPrograms: [

                        ],
                        federalPrograms: [

                        ]
                    },
                    2: {
                        enrollmentStatus: [

                        ],
                        studyPrograms: [

                        ],
                        federalPrograms: [

                        ]
                    },
                    3: {
                        enrollmentStatus: [

                        ],
                        studyPrograms: [

                        ],
                        federalPrograms: [

                        ]
                    },
                    4: {
                        enrollmentStatus: [

                        ],
                        studyPrograms: [

                        ],
                        federalPrograms: [

                        ]
                    },
                    5: {
                        enrollmentStatus: [

                        ],
                        studyPrograms: [

                        ],
                        federalPrograms: [

                        ]
                    },
                    6: {
                        enrollmentStatus: [

                        ],
                        studyPrograms: [

                        ],
                        federalPrograms: [

                        ]
                    },
                    7: {
                        enrollmentStatus: [

                        ],
                        studyPrograms: [

                        ],
                        federalPrograms: [

                        ]
                    }
                },
                prepareReport: {
                    id: 1,
                    year: 1617,
                    reportSchool: [

                    ],
                    enrollmentStatus: [

                    ],
                    studyPrograms: [

                    ],
                    federalPrograms: [

                    ],
                    1: {
                        years: [
                            { "key" : 1314, "value": '13-14'},
                            { "key" : 1415, "value": '14-15'},
                            { "key" : 1516, "value": '15-16'},
                            { "key" : 1617, "value": '16-17'},
                        ],
                        enrollmentStatus: [
                            1,2,3,4,5,6,7,8,9,10
                        ],
                        studyPrograms: [
                            1,2,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114,115,116,117,118,119,120,121,122,123,124,125,126,127,128,129,130,131,132,133,134,135
                        ],
                        federalPrograms: [
                            1,5,6,7,8,9,10
                        ],
                        date: 1
                    },
                    2: {
                        years: [
                            { "key" : 1314, "value": '13-14'},
                            { "key" : 1415, "value": '14-15'},
                            { "key" : 1516, "value": '15-16'},
                            { "key" : 1617, "value": '16-17'},
                        ],
                        enrollmentStatus: [
                            1,2,3,4,5,6,7,8,9,10
                        ],
                        studyPrograms: [
                            1,2,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114,115,116,117,118,119,120,121,122,123,124,125,126,127,128,129,130,131,132,133,134,135
                        ],
                        federalPrograms: [
                            1,5,6,7,8,9,10
                        ],
                        date: 1
                    },
                    3: {
                        years: [
                            { "key" : 1314, "value": '13-14'},
                            { "key" : 1415, "value": '14-15'},
                            { "key" : 1516, "value": '15-16'},
                            { "key" : 1617, "value": '16-17'},
                        ],
                        enrollmentStatus: [
                            1,2,3,4,5,6,7,8,9,10
                        ],
                        studyPrograms: [
                            1,2,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114,115,116,117,118,119,120,121,122,123,124,125,126,127,128,129,130,131,132,133,134,135
                        ],
                        federalPrograms: [
                            1,5,6,7,8,9,10
                        ],
                        date: 1
                    },
                    4: {
                        years: [
                            { "key" : 1314, "value": '13-14'},
                            { "key" : 1415, "value": '14-15'},
                            { "key" : 1516, "value": '15-16'},
                            { "key" : 1617, "value": '16-17'},
                        ],
                        enrollmentStatus: [
                            1,2,3,4,5,6,7,8,9,10
                        ],
                        studyPrograms: [
                            1,2,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114,115,116,117,118,119,120,121,122,123,124,125,126,127,128,129,130,131,132,133,134,135
                        ],
                        federalPrograms: [
                            1,5,6,7,8,9,10
                        ],
                        date: 1
                    },
                    5: {
                        years: [
                            { "key" : 1314, "value": '13-14'},
                            { "key" : 1415, "value": '14-15'},
                            { "key" : 1516, "value": '15-16'},
                            { "key" : 1617, "value": '16-17'},
                        ],
                        enrollmentStatus: [
                            3,4,5,8,9,10
                        ],
                        studyPrograms: [
                            1,2,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114,115,116,117,118,119,120,121,122,123,124,125,126,127,128,129,130,131,132,133,134,135
                        ],
                        federalPrograms: [
                            1,5,6,7,8,9,10
                        ],
                        date: 2
                    },
                    6: {
                        years: [
                            { "key" : 1516, "value": '15-16'},
                            { "key" : 1617, "value": '16-17'},
                        ],
                        enrollmentStatus: [
                            1
                        ],
                        studyPrograms: [
                            1,2,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114,115,116,117,118,119,120,121,122,123,124,125,126,127,128,129,130,131,132,133,134,135
                        ],
                        federalPrograms: [
                            1,5,6,7,8,9,10
                        ],
                        date: 2
                    },
                    7: {
                        years: [
                            { "key" : 1516, "value": '15-16'},
                            { "key" : 1617, "value": '16-17'},
                        ],
                        enrollmentStatus: [
                            1
                        ],
                        studyPrograms: [
                            1,2,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114,115,116,117,118,119,120,121,122,123,124,125,126,127,128,129,130,131,132,133,134,135
                        ],
                        federalPrograms: [
                            1,5,6,7,8,9,10
                        ],
                        date: 2
                    }
                }

            }
        },
        computed: {

        },
        methods: {
            toggleReport: function(report, event) {
                this.reports.prepareReport.id = report.id;
            },
            toggleEnrollmentStatus: function(EnrollmentStatus, event){
                id = this.reports.prepareReport.id;
                this.toggleArrayItem(this.reports.prepareReport[id].enrollmentStatus,EnrollmentStatus.EnrollmentStatusID);
                this.toggleArrayItem(this.reports.prepareReport.enrollmentStatus,EnrollmentStatus.EnrollmentStatusID);
            },
            toggleStudyProgram: function(studyProgram, event){
                id = this.reports.prepareReport.id;
                this.toggleArrayItem(this.reports.prepareReport[id].studyPrograms,studyProgram.ProgramID);
                this.toggleArrayItem(this.reports.prepareReport.studyPrograms,studyProgram.ProgramID);
            },
            toggleFederalProgram: function(federalProgram, event){
                id = this.reports.prepareReport.id;
                this.toggleArrayItem(this.reports.prepareReport[id].federalPrograms,federalProgram.FedPgmID);
                this.toggleArrayItem(this.reports.prepareReport.federalPrograms,federalProgram.FedPgmID);
            },
            toggleArrayItem: function(a, v) {
                var i = a.indexOf(v);

                if (i === -1)
                    a.push(v);
                else
                    a.splice(i,1);
            },
            isEnrollmentSelected: function(item) {
            },
            toggleAllStudyPrograms: function() {
                id = this.reports.prepareReport.id;
                if(this.reports.prepareReport[id].studyPrograms.length == this.reports.configure.studyPrograms.length) //deselect all
                {
                    this.reports.prepareReport[id].studyPrograms = [];
                }
                else // select all
                {
                    this.reports.prepareReport[id].studyPrograms = [];
                    var programs = this.reports.configure.studyPrograms;

                    for (index = 0; index < programs.length; ++index) {
                        var program = programs[index];
                        this.toggleArrayItem(this.reports.prepareReport[id].studyPrograms,program.ProgramID);
                    }

                }

                if(this.reports.prepareReport.studyPrograms.length == this.reports.configure.studyPrograms.length) //deselect all
                {
                    this.reports.prepareReport.studyPrograms = [];
                }
                else // select all
                {
                    this.reports.prepareReport.studyPrograms = [];
                    var programs = this.reports.configure.studyPrograms;

                    for (index = 0; index < programs.length; ++index) {
                        var program = programs[index];
                        this.toggleArrayItem(this.reports.prepareReport.studyPrograms,program.ProgramID);
                    }

                }
            },
            toggleAllenrollmentStatus: function() {
                id = this.reports.prepareReport.id;
                if(this.reports.prepareReport[id].enrollmentStatus.length == this.reports.configure.enrollmentStatus.length) //deselect all
                {
                    this.reports.prepareReport[id].enrollmentStatus = [];
                }
                else // select all
                {
                    this.reports.prepareReport[id].enrollmentStatus = [];
                    var programs = this.reports.configure.enrollmentStatus;

                    for (index = 0; index < programs.length; ++index) {
                        var program = programs[index];
                        this.toggleArrayItem(this.reports.prepareReport[id].enrollmentStatus,program.EnrollmentStatusID);
                    }

                }

                if(this.reports.prepareReport.enrollmentStatus.length == this.reports.configure.enrollmentStatus.length) //deselect all
                {
                    this.reports.prepareReport.enrollmentStatus = [];
                }
                else // select all
                {
                    this.reports.prepareReport.enrollmentStatus = [];
                    var programs = this.reports.configure.enrollmentStatus;

                    for (index = 0; index < programs.length; ++index) {
                        var program = programs[index];
                        this.toggleArrayItem(this.reports.prepareReport.enrollmentStatus,program.EnrollmentStatusID);
                    }

                }
            },
            toggleAllfederalPrograms: function() {
                id = this.reports.prepareReport.id;
                if(this.reports.prepareReport[id].federalPrograms.length == this.reports.configure.federalPrograms.length) //deselect all
                {
                    this.reports.prepareReport[id].federalPrograms = [];
                }
                else // select all
                {
                    this.reports.prepareReport[id].federalPrograms = [];
                    var programs = this.reports.configure.federalPrograms;

                    for (index = 0; index < programs.length; ++index) {
                        var program = programs[index];
                        this.toggleArrayItem(this.reports.prepareReport[id].federalPrograms,program.FedPgmID);
                    }

                }

                if(this.reports.prepareReport.federalPrograms.length == this.reports.configure.federalPrograms.length) //deselect all
                {
                    this.reports.prepareReport.federalPrograms = [];
                }
                else // select all
                {
                    this.reports.prepareReport.federalPrograms = [];
                    var programs = this.reports.configure.federalPrograms;

                    for (index = 0; index < programs.length; ++index) {
                        var program = programs[index];
                        this.toggleArrayItem(this.reports.prepareReport.federalPrograms,program.FedPgmID);
                    }

                }
            }
        },
        ready: function () {
            $.getJSON('/reports/init', function(data){
                this.reports.configure.enrollmentStatus = data.enrollment;
                this.reports.configure.studyPrograms = data.study;
                this.reports.configure.federalPrograms = data.federal;
                this.reports.configure.awardYears = data.awardYears;
            }.bind(this));
        }

    });
}


Vue.filter('sham-date', function(value, formatString) {
    if(!value) return "";

    value = value.substr(0, value.indexOf(' '));
    return value;
});
Vue.filter('yesno', function(value,formatString)
{
    if(value == 1)
        return "Yes";
    else
        return "No";
});

Vue.filter('subtotal', function (list, key1) {
    return list.reduce(function(total, item) {
        return total + parseInt(item.key1)
    }, 0)
});

Vue.filter('pluckSum', function (list, key1, key2) {
    return list.reduce(function(total, item) {
        return total + item[key1] * item[key2]
    }, 0)
});

Vue.filter('percentage', function(value, decimals) {
    if(!value) {
        value = 0;
    }

    if(!decimals) {
        decimals = 0;
    }

    value = value * 100;
    value = Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals);
    value = value + '%';
    return value;
});

Vue.filter('insert', function(value, index, string) {
    if(value)
        return value.substr(0, index) + string + value.substr(index);
    else
        return "";
});

Vue.filter('phone', function(value) {
    return value.replace(/(\d\d\d)(\d\d\d)(\d\d\d\d)/, '$1-$2-$3');
});


Vue.filter('year', function(value) {

    if(value)
    {
        var values = value.split(' ');
        var m = new Date(values[0]);
        return (m.getUTCMonth()+1) +"/"+ m.getUTCDate() + "/" + m.getUTCFullYear();
    }
    else
        return "";
});

Vue.filter('grade', function(value) {

    if(value == 0 || !value)
        return "";
    else
        return value;
});

Vue.filter('yearSeparator', function(value){
    var string = txt1.slice(0, 2) + "-" + txt1.slice(2);
    return string;
});

Vue.filter('ssn', function(value){
    var pad = "000000000";
    var ans = (pad + value).slice(-pad.length)

    return ans.replace(/^(\d{3})(\d{2})(\d{4})$/, '$1-$2-$3');
});

Vue.filter('housing', function(value) {
    if(value == "1")
    {
        return "On Campus"
    }
    if(value == "2")
    {
        return "With Parents"
    }
    if(value == "3")
    {
        return "Off Campus"
    }
});


if ($('#app-notification').length) {
    vm = new Vue({
        el: '#app-notification',
        components: {
            modal: VueStrap.modal
        },
        data: {
            showModal: false,
            details: []
        },
        methods: {
            showDetails: function(id) {
                var self = this;
                $.getJSON('/api/notification/'+id, function(resp){
                   console.log(resp);
                   self.details = resp.data;
                    console.log(self.details);
                    self.showModal = true;
                });


            }
        }
    });
}

$('#datatable-notifications').DataTable({
    "order": [[ 2, "desc" ]]
});

$('.dropdown-notifications-menu').on('click', '.btn-marks-as-read', function(e){
    e.stopPropagation();
    var id = $(this).data('id');
    S.postRequest('/api/notification/mark-as-read',{id:id},
        function(resp){
            $('.notification-'+id).remove();
        },
        function(resp){

        });

});

$(document).on('click', '.dropdown-menu', function (e) {
    e.stopPropagation();
});

